import axios from "axios";
import { useNavigate } from "react-router-dom";
import Constant from "../../shared/_helpers/constants";
import { Spinner, Modal, Form } from "react-bootstrap";
import React, { useState, useEffect, useContext } from "react";

import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import moment from "moment";

import BackArrow from "../../assets/images/back-arrow.svg";
import overviewIcon from "../../assets/images/overview.svg";
import invoiceIcon from "../../assets/images/invoiceIcons.svg";
import paymentIcon from "../../assets/images/paymentIcon.svg";
import inventoryImg from "../../assets/images/inventory.svg";
import workersImg from "../../assets/images/workers.svg";
import hybridUsers from "../../assets/images/Hybrid-Users.svg";
import fieldUsers from "../../assets/images/Manufacturing-Process-rafiki.svg";
import calendarMinusIcon from "../../assets/images/calendar-minus.svg";
import SearchIcon from "../../assets/images/Search.svg";
import periodIcon from "../../assets/images/Period.png";
import cardPatternIcon from "../../assets/images/card-pattern.png";
import cardLogoIcon from "../../assets/images/card-logo.svg";
import deleteCardIcon from "../../assets/images/delete-card.svg";
// import fileInvoiceDollar from "../../assets/images/file-invoice-dollar.png";
import UserContext from "../../context/userContext";

import DataTable from "react-data-table-component";
import "./billingDetails.css";

import { loadStripe } from "@stripe/stripe-js";
import { CardElement, Elements, useStripe, useElements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe(Constant.STRIPE_PK);

const styleOptions = {
  style: {
    base: {
      fontSize: "20px",
      color: "#424770",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#9e2146",
    },
  },
};

const Notification = () => {
  const { enqueueSnackbar } = useSnackbar();
  const account = useContext(UserContext);

  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const [billingOverview, setBillingOverview] = useState();
  const [paymentMethod, setPaymentMethod] = useState();
  const [invoiceDetails, setInvoiceDetails] = useState([]);
  const [activeTabDetails, setActiveTab] = useState("Overview");

  const [showDeletePaymentCard, setShowDeletePaymentCard] = useState(false);
  const navigate = useNavigate();

  const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();

    const [errorMessage, setErrorMessage] = useState(null);

    const handleSubmit = async (event) => {
      event.preventDefault();

      if (elements == null || stripe == null) {
        return;
      }

      const { error: submitError, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });

      if (submitError) {
        setErrorMessage(submitError.message);
        return;
      }

      console.log("PaymentMethod", paymentMethod);
      const { data } = await axios.get(`${Constant.BASE_URL}/api/APIv1GetCanoAccount?CanoID=${account.CanoID}`);
      console.log(data);

      axios
        .get(
          `${Constant.BASE_URL}/api/APIv1SaveStripeAttachPaymentMethod?PaymentMethodID=${paymentMethod.id}&CompanyID=${data.StripeCustomerID}`
        )
        .then((response) => {
          if (response.data.status === "success") {
            setPaymentMethod(response.data);
            axios
              .post(`${Constant.BASE_URL}/api/APIv1SaveCanoAccount`, {
                ...data,
                StripePaymentMethodID: response.data.message.id,
                RestrictedCompanies: data?.RestrictedCompanies || [],
              })
              .then((response) => {
                if (response.data.status === "success") {
                  enqueueSnackbar(`Card added successfully`, { variant: "success" });
                } else {
                  enqueueSnackbar(response.data.message, { variant: "error" });
                }
              })
              .catch((error) => {
                enqueueSnackbar(response.data.message, { variant: "error" });
              });
          } else {
            enqueueSnackbar(response.data.message, { variant: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar(`${err.response.data.message}`, { variant: "error" });
        });
    };

    return (
      <form onSubmit={handleSubmit} className="mx-4">
        <CardElement options={styleOptions} className="mt-2 border p-3" />

        <div className="d-flex justify-content-between my-5">
          <div className="cancel-btn">
            <button onClick={(e) => navigate("/")} style={{ opacity: "1" }}>
              Cancel
            </button>
          </div>

          <div className="submit-btn">
            <button type="submitn" className="conform_btn" disabled={!stripe || !elements} style={{ opacity: "1" }}>
              Submit
            </button>
          </div>
        </div>
        {errorMessage && <div>{errorMessage}</div>}
      </form>
    );
  };



  const checkCompanyPaymentStatus = (cp) => {

    const { StripePaymentCharged, TrialDays, JoiningDate } = cp;
    const currentDate = new Date();

    if (JoiningDate) {
      const joiningDate = new Date(JoiningDate);
      const trialEndDate = new Date(joiningDate);
      trialEndDate.setDate(trialEndDate.getDate() + TrialDays);

      if (currentDate <= trialEndDate) {
        return "Active";
      }
    }

    if (StripePaymentCharged === true) {
      return "Active";
    }

    return "Inactive";
  };


  useEffect(() => {
    if (window.location?.hash === "#invoices") {
      setActiveTab("invoices");
    }
    const fetchData = async () => {
      try {
        const { data } = await axios.get(`${Constant.BASE_URL}/api/APIv1GetCanoAccount?CanoID=${account.CanoID}`);
        const [CanoUsers, paymentMethodData, invoiceData] = await Promise.all([
          axios.get(`${Constant.BASE_URL}/api/APIv1GetCanoUsersForCanoAccount?CanoID=${account.CanoID}`),
          axios.get(`${Constant.BASE_URL}/api/APIv1GetStripePaymentMethod?PaymentMethodID=${data.StripePaymentMethodID}`),
          axios.get(`${Constant.BASE_URL}/api/APIv1GetStripeAllPaymentIntentForCustomer?CustomerID=${data.StripeCustomerID}`),
        ]);

        let UsersCounts = {};
        for (let i of CanoUsers.data) {
          UsersCounts[i["UserType"]] = (UsersCounts[i["UserType"]] || 0) + 1;
        }

        let PaymentStatus = checkCompanyPaymentStatus(data);

        setBillingOverview({
          PaymentStatus,
          JoiningDate: data["JoiningDate"],
          TrialsDays: data["TrialDays"],
          TrialEnds: moment(data["JoiningDate"]).add(data["TrialDays"], "days").format("MM/DD/YYYY"),

          HybridUser: UsersCounts["Hybrid User"] || 0,
          InventoryStaff: UsersCounts["Inventory Staff"] || 0,
          Tech: UsersCounts["Tech"] || 0,
          Field: UsersCounts["Field"] || 0,
          PricePerInventoryStaff: data.PricePerInventoryStaff || 0,
          PricePerTech: data.PricePerWorker || 0,
          PricePerHybridUser: data.PricePerHybridUser || 0,
          PricePerField: data.PricePerField || 0,
        });

        setPaymentMethod(data.StripePaymentMethodID ? paymentMethodData.data : false);

        const formattedResponse = invoiceData.data.data?.map((item) => {
          const timestamp = item.created;
          const date = new Date(timestamp * 1000);

          // Get year, month, and day
          const year = date.getFullYear();
          const monthIndex = date.getMonth(); // Month index from 0 to 11
          const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];
          const monthName = monthNames[monthIndex];

          const day = String(date.getDate()).padStart(2, "0");

          // Form the date in the "YYYY-MM-DD" format
          const formattedDate = `${year}/${monthIndex + 1}/${day}`;

          // Return a new object with the formatted date and other properties
          return {
            ...item,
            created: formattedDate,
            month: monthIndex + 1,
            monthName,
            year,
          };
        });

        const monthWiseData = [];
        formattedResponse?.forEach((element) => {
          const newKey = element.monthName + " " + element.year;
          if (monthWiseData[newKey]) {
            monthWiseData[newKey].count = monthWiseData[newKey].count + 1;
            monthWiseData[newKey].amount = monthWiseData[newKey].amount + element.amount_received;

            monthWiseData[newKey].data.push(element);
          } else {
            monthWiseData[newKey] = {
              count: 1,
              date: newKey,
              amount: element.amount_received,
              data: [element],
            };
          }
        });
        const monthWiseDataObject = Object.values(monthWiseData).map((item) => ({
          date: item.date,
          count: item.count,
          amount: item.amount,
          data: item.data,
        }));

        setInvoiceDetails(monthWiseDataObject);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [account.CanoID]);

  const handleBillingRowClick = (selectedDate) => {
    if (selectedDate) {
      navigate("/billing-details/invoices-details", {
        state: { billingData: selectedDate, CanoID: account.CanoID },
      });
    }
  };

  const confirmHandleDeleteCard = async () => {
    setShowDeletePaymentCard(false);
    const getAccountData = await axios.get(`${Constant.BASE_URL}/api/APIv1GetCanoAccount?CanoID=${account.CanoID}`);
    axios
      .get(`${Constant.BASE_URL}/api/APIv1DeleteStripePaymentMethod?PaymentMethodID=${getAccountData.data.StripePaymentMethodID}`)
      .then((response) => {
        if (response.data.status === "success") {
          axios
            .post(`${Constant.BASE_URL}/api/APIv1SaveCanoAccount`, {
              ...getAccountData.data,
              StripePaymentMethodID: "",
              RestrictedCompanies: getAccountData.data?.RestrictedCompanies || [],
            })
            .then((response) => {
              if (response.data.status === "success") {
                setPaymentMethod(false);
                enqueueSnackbar(`Card removed successfully`, { variant: "success" });
              } else {
                enqueueSnackbar(response.data.message, { variant: "error" });
              }
            })
            .catch((error) => {
              enqueueSnackbar(response.data.message, { variant: "error" });
            });
        } else {
          enqueueSnackbar(response.data.message, { variant: "error" });
        }
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(`${err.message}`, { variant: "error" });
      });
  };

  function daysInThisMonth() {
    var now = new Date();
    return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
  }

  var getRemanningDays = function () {
    var date = new Date();
    var time = new Date(date.getTime());
    time.setMonth(date.getMonth() + 1);
    time.setDate(0);
    return time.getDate() > date.getDate() ? time.getDate() - date.getDate() : 0;
  };

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split("-").map(Number);
    // Month is 0-indexed in JavaScript Date, so subtract 1 from the month
    return new Date(year, month - 1, day);
  };

  const checkEmployeePaymentStatus = (e_payment) => {
    if (parseDate(e_payment?.StripeSubscriptionID) >= new Date()) {
      return true;
    } else {
      return false;
    }
  };

  const handlePayNowClick = async () => {
    const getCanoAccount = await axios.get(`https://celtic.azurewebsites.net/api/APIv1GetCanoAccount?CanoID=${account.CanoID}`);
    let statusMessage;
    let paymentFlag = false;
    try {
      if (getCanoAccount?.data?.StripeCustomerID === "" || getCanoAccount?.data?.StripeCustomerID === null) {
        statusMessage = "ERROR: Missing CustomerID and PaymentMethodID ";
      } else if (getCanoAccount?.data?.StripePaymentMethodID === "" || getCanoAccount?.data?.StripePaymentMethodID === null) {
        statusMessage = "ERROR: Missing PaymentMethodID ";
      } else {
        let UsersCounts = {};
        let { data } = await axios.get(
          `https://celtic.azurewebsites.net/api/APIv1GetCanoUsersForCanoAccount?CanoID=${getCanoAccount?.data?.id}`
        );

        if (data.length > 0) {
          for (let i of data) {
            if (checkEmployeePaymentStatus(i) === false) {
              UsersCounts[i["UserType"]] = (UsersCounts[i["UserType"]] || 0) + 1;
            }
          }
          let UsersPrice = {
            "Hybrid User": getCanoAccount.data["PricePerHybridUser"],
            Tech: getCanoAccount.data["PricePerWorker"],
            "Inventory Staff": getCanoAccount.data["PricePerInventoryStaff"],
            "Field": getCanoAccount.data["PricePerField"],
          };

          let TotalUsersPrice = 0;
          for (const userType in UsersPrice) {
            const userCount = UsersCounts[userType] || 0;
            const pricePerUser = UsersPrice[userType] || 0;
            TotalUsersPrice += userCount * pricePerUser;
          }
          console.log({ UsersCounts, TotalUsersPrice, UsersPrice });
          try {
            const saveIntent = await axios.post(`https://celtic.azurewebsites.net/api/APIv1SaveStripePaymentIntentForUser`, {
              Price: Math.round((parseInt(TotalUsersPrice) / daysInThisMonth()) * getRemanningDays()),
              Description: `Monthly payment intent for all active employees of ${getCanoAccount?.data?.id}`,
              CustomerID: getCanoAccount?.data?.StripeCustomerID,
              PaymentMethodID: getCanoAccount?.data?.StripePaymentMethodID,
            });

            if (saveIntent.data.status === "success") {
              statusMessage = saveIntent.data.message.id + " - " + moment().format("YYYY-MM-DD");
              paymentFlag = true;
            } else {
              statusMessage = "Error :" + saveIntent.data.message;
            }
          } catch (error) {
            statusMessage = "Error :" + error.data.message;
          }
        } else {
          statusMessage = "ERROR: Trial period, no payment.";
        }
      }
    } catch (error) {
      console.log("Not valid date", getCanoAccount?.data?.StripePaymentStatus);
      statusMessage = "Error :" + error.message;
    }
    if (paymentFlag === false) enqueueSnackbar("Payment " + statusMessage, { variant: "error" });

    axios
      .post(`https://celtic.azurewebsites.net/api/APIv1SaveCanoAccount`, {
        ...getCanoAccount.data,
        StripePaymentStatus: statusMessage || getCanoAccount?.data?.StripePaymentStatus,
        RestrictedCompanies: getCanoAccount?.data?.RestrictedCompanies || [],
      })
      .then((response) => {
        if (paymentFlag) {
          if (response.data.status === "success") {
            enqueueSnackbar("Payment charged successfully.", { variant: "success" });
            setBillingOverview((prev) => {
              return { ...prev, PaymentStatus: "Active" };
            });
          } else {
            enqueueSnackbar(response.data.message, { variant: "error" });
          }
        }
      })
      .catch((error) => {
        console.error("Error updating status:", error);
      });
  };

  useEffect(() => {
    if (search.match("^[a-zA-Z0-9]*$") == null) {
      return setFilteredData([]);
    }
    const resultData = invoiceDetails.filter((user) => {
      return user?.date?.toLowerCase().match(search?.toLowerCase());
    });
    setFilteredData(resultData);
  }, [search, invoiceDetails]);

  const columns = [
    {
      name: "Period",
      minWidth: "300px",
      cell: (row) => (
        <div>
          <img src={periodIcon} alt="periodImage" style={{ width: 40 }} />
          <span className="mx-2">{row?.date || "-"}</span>
        </div>
      ),
    },
    {
      name: "Amount ($)",
      selector: (row) => (row && row?.amount !== undefined ? row?.amount / 100 : ""),
      sortable: true,
      width: "250px",
    },
    {
      name: "Count",
      selector: (row) => row?.count || "-",
      sortable: true,
      width: "250px",
    },
  ];

  return (
    <div className="home_content inner-home-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="page-heading d-flex">
              <Link to="/">
                <img src={BackArrow} alt="arrow" />
              </Link>
              <h4>Billing </h4>
            </div>
          </div>
        </div>
        <div className="d-flex mt-4">
          <nav>
            <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
              <a
                className={activeTabDetails === "Overview" ? "nav-item nav-link custom-tab active" : "nav-item nav-link custom-tab"}
                id="nav-home-tab"
                data-toggle="tab"
                href="#nav-home"
                role="tab"
                aria-controls="nav-home"
                aria-selected="true"
              >
                <img src={overviewIcon} alt="Overview" className="px-2 py-1" style={{ width: "35px" }} />
                Overview
              </a>
              <a
                className={activeTabDetails === "invoices" ? "nav-item nav-link custom-tab active" : "nav-item nav-link custom-tab"}
                id="nav-profile-tab"
                data-toggle="tab"
                href="#nav-profile"
                role="tab"
                aria-controls="nav-profile"
                aria-selected="false"
              >
                <img src={invoiceIcon} alt="invoiceIcon" className="px-2 py-1" style={{ width: "35px" }} />
                Invoices
              </a>
              <a
                className="nav-item nav-link custom-tab"
                id="nav-contact-tab"
                data-toggle="tab"
                href="#nav-contact"
                role="tab"
                aria-controls="nav-contact"
                aria-selected="false"
              >
                <img src={paymentIcon} alt="paymentIcon" className="px-2 py-1" style={{ width: "35px" }} />
                Payment Method
              </a>
            </div>
          </nav>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            <div className="tab-content" id="nav-tabContent">
              <div
                className={activeTabDetails === "Overview" ? "tab-pane fade show active" : "tab-pane fade show"}
                id="nav-home"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
              >
                {billingOverview ? (
                  <>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="white-box-sec mt-3">
                          <div className="inventory-staff">
                            <div className="row">
                              <div className="col-lg-12">
                                <h4>
                                  <b>Inventory Staff</b>
                                </h4>
                                <p>{billingOverview?.["InventoryStaff"]} Members</p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6 d-flex align-items-end">
                                <div className="card-botom-text">
                                  <h5>
                                    ${(billingOverview?.["InventoryStaff"] * billingOverview?.["PricePerInventoryStaff"])?.toFixed(2)}
                                  </h5>
                                  <p className="mb-0">${billingOverview?.["PricePerInventoryStaff"]}/Member</p>
                                </div>
                              </div>
                              <div className="col-6 d-flex justify-content-end">
                                <img src={inventoryImg} alt="inventoryImg" width="100%" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="white-box-sec mt-3">
                          <div className="inventory-staff">
                            <div className="row">
                              <div className="col-lg-12">
                                <h4>
                                  <b>Tech</b>
                                </h4>
                                <p>{billingOverview?.["Tech"]} Members</p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6 d-flex align-items-end">
                                <div className="card-botom-text">
                                  <h5>${(billingOverview?.["Tech"] * billingOverview["PricePerTech"])?.toFixed(2)}</h5>
                                  <p className="mb-0">${billingOverview["PricePerTech"]}/Member</p>
                                </div>
                              </div>
                              <div className="col-6 d-flex justify-content-end">
                                <img src={workersImg} alt="workersImg" width="98%" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="white-box-sec mt-3">
                          <div className="inventory-staff">
                            <div className="row">
                              <div className="col-lg-12">
                                <h4>
                                  <b>Hybrid Users</b>
                                </h4>
                                <p>{billingOverview?.["HybridUser"]} Members</p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6 d-flex align-items-end">
                                <div className="card-botom-text">
                                  <h5>${(billingOverview?.["HybridUser"] * billingOverview?.["PricePerHybridUser"])?.toFixed(2)}</h5>
                                  <p className="mb-0">${billingOverview?.["PricePerHybridUser"]}/Member</p>
                                </div>
                              </div>
                              <div className="col-6 d-flex justify-content-end">
                                <img src={hybridUsers} alt="hybridUsers" width="64%" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="white-box-sec mt-3">
                          <div className="inventory-staff">
                            <div className="row">
                              <div className="col-lg-12">
                                <h4>
                                  <b>Field</b>
                                </h4>
                                <p>{billingOverview?.["Field"]} Members</p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6 d-flex align-items-end">
                                <div className="card-botom-text">
                                  <h5>${(billingOverview?.["Field"] * billingOverview?.["PricePerField"])?.toFixed(2)}</h5>
                                  <p className="mb-0">${billingOverview?.["PricePerField"]}/Member</p>
                                </div>
                              </div>
                              <div className="col-6 d-flex justify-content-end">
                                <img src={fieldUsers} alt="hybridUsers" width="100%" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-12">
                        <div className="white-box-sec mt-3">
                          <div className="row">
                            <div className="col-lg-12 inventory-staff">
                              <h4>
                                <span
                                  className="close-table "
                                  style={{
                                    backgroundColor:
                                      billingOverview?.["PaymentStatus"] === "Active"
                                        ? "green "
                                        : billingOverview?.["PaymentStatus"] === "Inactive"
                                          ? "red"
                                          : billingOverview?.["PaymentStatus"] === "Trial"
                                            ? "#1477F8 "
                                            : "#000",
                                  }}
                                >
                                  {billingOverview?.["PaymentStatus"]}
                                </span>
                              </h4>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-8">
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="d-flex mt-3">
                                    <div className="me-2">
                                      <img src={calendarMinusIcon} alt="dkfjd" />
                                    </div>
                                    <div className="ml-3">
                                      <p className="mb-1">Start Date</p>
                                      <p className="mb-0">
                                        <b>{billingOverview?.JoiningDate}</b>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  {billingOverview["PaymentStatus"] === "Trial" ? (
                                    <>
                                      <div className="d-flex mt-3">
                                        <div className="me-2">
                                          <img src={calendarMinusIcon} alt="calendarMinusIcon" />
                                        </div>
                                        <div className="ml-3">
                                          <p className="mb-1">End Date</p>
                                          <p className="mb-0">
                                            <b>{billingOverview["TrialEnds"]}</b>
                                          </p>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                            {billingOverview["PaymentStatus"] === "Inactive" ? (
                              <div className="col-lg-4" onClick={handlePayNowClick}>
                                <button className="pay-now">Pay Now</button>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <p>Loading....</p>
                )}
              </div>

              <div
                className={activeTabDetails === "invoices" ? "tab-pane fade  show inventory-count active" : "tab-pane fade inventory-count"}
                id="nav-profile"
                role="tabpanel"
                aria-labelledby="nav-profile-tab"
              >
                <DataTable
                  columns={columns}
                  data={search ? filteredData : invoiceDetails}
                  onRowClicked={(row) => handleBillingRowClick(row)}
                  pagination
                  subHeader
                  pointerOnHover
                  subHeaderComponent={
                    <div className="d-flex justify-content-between w-100 search_head col-lg-5">
                      <div className="input-group w-50">
                        <img src={SearchIcon} className="search-icon " alt="search" />
                        <input
                          type="text"
                          placeholder="Search"
                          className="form-control w-25 mt-1"
                          style={{ borderRadius: "40px", paddingLeft: "40px" }}
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                      </div>
                    </div>
                  }
                  subHeaderAlign="left"
                  progressPending={loading}
                  progressComponent={
                    <div className="d-flex justify-content-center align-items-center">
                      <Spinner animation="border" role="status" style={{ color: "skyblue" }}>
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </div>
                  }
                />
              </div>
              <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                {paymentMethod ? (
                  <div className="row">
                    <div className=" col-lg-5 col-md-9 col-12">
                      <div className="credit-card-custom" style={{ backgroundImage: `url(${cardPatternIcon})` }}>
                        <div className=" d-flex justify-content-between">
                          <div className="">
                            <img src={cardLogoIcon} width="40px" className="mt-2" alt="cardLogo" />
                          </div>
                          <div className=" text-right">
                            <img src={deleteCardIcon} width="35px" alt="deleteCard" onClick={() => setShowDeletePaymentCard(true)} />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <h5 className="">{"XXXX XXXX XXXX " + paymentMethod?.message?.card?.last4} </h5>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-8">
                            <h4>{paymentMethod?.message?.card?.brand?.toUpperCase()}</h4>
                          </div>
                          <div className="col-4">
                            <h6>VALID THRU</h6>
                            <p>
                              {paymentMethod?.message?.card?.exp_month > 9
                                ? paymentMethod?.message?.card?.exp_month + " / " + paymentMethod?.message?.card?.exp_year
                                : "0" + paymentMethod?.message?.card?.exp_month + " / " + paymentMethod?.message?.card?.exp_year}
                            </p>
                          </div>
                        </div>

                        <Modal show={showDeletePaymentCard} onHide={() => setShowDeletePaymentCard(false)} centered>
                          <Modal.Header>
                            <h6 style={{ fontWeight: "600", marginBottom: "0!important" }}>Are you Sure?</h6>
                          </Modal.Header>
                          <Modal.Body>
                            <Form.Group className="my-3" controlId="exampleForm.ControlInput1">
                              <Form.Label style={{ fontSize: "14px" }}>Are you sure you would like to delete payment card? </Form.Label>
                            </Form.Group>
                          </Modal.Body>
                          <Modal.Footer>
                            <button className="cancel_btn" onClick={() => setShowDeletePaymentCard(false)}>
                              Cancel
                            </button>
                            <button className="conform_btn" onClick={confirmHandleDeleteCard}>
                              Submit
                            </button>
                          </Modal.Footer>
                        </Modal>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Elements stripe={stripePromise}>
                    <CheckoutForm />
                  </Elements>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;

const userData = JSON.parse(localStorage.getItem("CanoUserDetails"));
const userLoginType = userData?.UserType;

const iconStyle = {
  fill: "white",
};
const menuItem = [
  {
    path: "/",
    name: "Dashboard",

    icon: (
      <svg className="icon-nav" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          d="M9.56809 9H3.43176C2.64225 9 2 8.31315 2 7.46866V3.53118C2 2.68685 2.64225 2 3.43176 2H9.56809C10.3578 2 11 2.68685 11 3.53118V7.46866C11 8.31315 10.3578 9 9.56809 9ZM3.43176 3.31249C3.31895 3.31249 3.22727 3.41054 3.22727 3.53118V7.46866C3.22727 7.58946 3.31895 7.68751 3.43176 7.68751H9.56809C9.68105 7.68751 9.77273 7.58946 9.77273 7.46866V3.53118C9.77273 3.41054 9.68105 3.31249 9.56809 3.31249H3.43176Z"
          fill="white"
        />
        <path
          d="M9.56809 22H3.43176C2.64225 22 2 21.3272 2 20.5001V11.5001C2 10.6728 2.64225 10 3.43176 10H9.56809C10.3578 10 11 10.6728 11 11.5001V20.5001C11 21.3272 10.3578 22 9.56809 22ZM3.43176 11.2857C3.31895 11.2857 3.22727 11.3818 3.22727 11.5001V20.5001C3.22727 20.6182 3.31895 20.7143 3.43176 20.7143H9.56809C9.68105 20.7143 9.77273 20.6182 9.77273 20.5001V11.5001C9.77273 11.3818 9.68105 11.2857 9.56809 11.2857H3.43176Z"
          fill="white"
        />
        <path
          d="M20.5682 22H14.4319C13.6422 22 13 21.3132 13 20.4688V16.5313C13 15.6868 13.6422 15 14.4319 15H20.5682C21.3578 15 22 15.6868 22 16.5313V20.4688C22 21.3132 21.3578 22 20.5682 22ZM14.4319 16.3125C14.319 16.3125 14.2273 16.4105 14.2273 16.5313V20.4688C14.2273 20.5895 14.319 20.6875 14.4319 20.6875H20.5682C20.681 20.6875 20.7727 20.5895 20.7727 20.4688V16.5313C20.7727 16.4105 20.681 16.3125 20.5682 16.3125H14.4319Z"
          fill="white"
        />
        <path
          d="M20.5682 14H14.4319C13.6422 14 13 13.3272 13 12.4999V3.49994C13 2.67283 13.6422 2 14.4319 2H20.5682C21.3578 2 22 2.67283 22 3.49994V12.4999C22 13.3272 21.3578 14 20.5682 14ZM14.4319 3.28571C14.319 3.28571 14.2273 3.38176 14.2273 3.49994V12.4999C14.2273 12.6182 14.319 12.7143 14.4319 12.7143H20.5682C20.681 12.7143 20.7727 12.6182 20.7727 12.4999V3.49994C20.7727 3.38176 20.681 3.28571 20.5682 3.28571H14.4319Z"
          fill="white"
        />
      </svg>
    ),
  },
  {
    path: "/inventory-charges",
    name: "Warehouse to Job",
    icon: (
      <svg className="icon-nav" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <mask id="mask0_5857_4484" style={iconStyle} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
          <path d="M0 1.90735e-06H24V24H0V1.90735e-06Z" fill="white" />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 21.892C0 23.0582 0.947706 24 2.11223 24H21.8877C23.0523 24 24 23.0583 24 21.892V21.4338C24 18.7687 21.8335 16.6119 19.1656 16.6119H13.7361V18.0182H19.1656C21.0609 18.0182 22.5937 19.5494 22.5937 21.4338V21.892C22.5937 22.2776 22.2797 22.5938 21.8877 22.5938H2.11223C1.72033 22.5938 1.40625 22.2776 1.40625 21.892V21.4338C1.40625 19.5494 2.93908 18.0182 4.83441 18.0182H10.1813V16.6119H4.83441C2.16646 16.6119 0 18.7687 0 21.4338V21.892Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.42188 16.8643C9.42188 18.2642 10.5596 19.3955 11.9587 19.3955C13.3577 19.3955 14.4955 18.2642 14.4955 16.8643V15.2833H13.0892V16.8643C13.0892 17.4836 12.5851 17.9893 11.9587 17.9893C11.3323 17.9893 10.8281 17.4836 10.8281 16.8643V15.2833H9.42188V16.8643Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.91699 24C5.30532 24 5.62012 23.6852 5.62012 23.2969V21.2824C5.62012 20.894 5.30532 20.5793 4.91699 20.5793C4.52867 20.5793 4.21387 20.894 4.21387 21.2824V23.2969C4.21387 23.6852 4.52867 24 4.91699 24Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.083 24C19.4713 24 19.7861 23.6852 19.7861 23.2969V21.2824C19.7861 20.894 19.4713 20.5793 19.083 20.5793C18.6947 20.5793 18.3799 20.894 18.3799 21.2824V23.2969C18.3799 23.6852 18.6947 24 19.083 24Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9587 14.9541C9.77898 14.9541 8.01562 13.193 8.01562 11.0251V7.12054C8.01562 6.73221 7.70083 6.41741 7.3125 6.41741C6.92417 6.41741 6.60938 6.73221 6.60938 7.12054V11.0251C6.60938 13.9737 9.00637 16.3604 11.9587 16.3604C14.911 16.3604 17.308 13.9737 17.308 11.0251V7.12054C17.308 6.73221 16.9932 6.41741 16.6049 6.41741C16.2165 6.41741 15.9017 6.73221 15.9017 7.12054V11.0251C15.9017 13.193 14.1384 14.9541 11.9587 14.9541Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.41602 20.252V17.3149H7.00977V20.7858C7.00977 21.2696 7.40262 21.6582 7.88272 21.6582H16.1192C16.5993 21.6582 16.9921 21.2695 16.9921 20.7858V17.3149H15.5858V20.252H8.41602Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.14062 7.82324H17.7767V5.80209C17.7767 2.59545 15.1699 -0.000429153 11.9587 -0.000429153C8.74747 -0.000429153 6.14062 2.59545 6.14062 5.80209V7.82324ZM7.54687 6.41699V5.80209C7.54687 3.37613 9.52009 1.40582 11.9587 1.40582C14.3973 1.40582 16.3705 3.37613 16.3705 5.80209V6.41699H7.54687Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.73438 7.12012C4.73438 7.50844 5.04917 7.82324 5.4375 7.82324H18.4799C18.8682 7.82324 19.183 7.50844 19.183 7.12012C19.183 6.73179 18.8682 6.41699 18.4799 6.41699H5.4375C5.04917 6.41699 4.73438 6.73179 4.73438 7.12012Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.26465 5.12934C9.26465 6.61718 10.4708 7.82324 11.9586 7.82324C13.4464 7.82324 14.6525 6.61717 14.6525 5.12934C14.6525 3.64152 13.4464 2.43538 11.9586 2.43538C10.4708 2.43538 9.26465 3.64151 9.26465 5.12934ZM11.9586 6.41699C11.2474 6.41699 10.6709 5.84049 10.6709 5.12934C10.6709 4.41816 11.2474 3.84163 11.9586 3.84163C12.6697 3.84163 13.2463 4.41815 13.2463 5.12934C13.2463 5.8405 12.6697 6.41699 11.9586 6.41699Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9585 3.8418C12.3468 3.8418 12.6616 3.527 12.6616 3.13867V0.70286C12.6616 0.314535 12.3468 -0.000265121 11.9585 -0.000265121C11.5702 -0.000265121 11.2554 0.314535 11.2554 0.70286V3.13867C11.2554 3.527 11.5702 3.8418 11.9585 3.8418Z"
          fill="white"
        />
      </svg>
    ),
  },
  {
    path: "/warehouse",
    name: "Warehouse Moves",
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          d="M17.5 20C16.1217 20 15 18.8785 15 17.5005V10.8351C15 10.376 14.6267 10.0019 14.1667 10.0019H5.83333C5.37333 10.0019 5 10.376 5 10.8351V17.5005C5 18.8785 3.87833 20 2.5 20C1.12167 20 0 18.8785 0 17.5005V8.10556C0 6.71832 0.686667 5.42773 1.83583 4.65204L7.66917 0.716116C9.085 -0.238705 10.915 -0.238705 12.3308 0.716116L18.1642 4.65204C19.3133 5.42773 20 6.71832 20 8.10556V17.5005C20 18.8785 18.8783 20 17.5 20ZM5.83333 8.33552H14.1667C15.545 8.33552 16.6667 9.45698 16.6667 10.8351V17.5005C16.6667 17.9595 17.04 18.3336 17.5 18.3336C17.96 18.3336 18.3333 17.9595 18.3333 17.5005V8.10556C18.3333 7.27322 17.9217 6.49836 17.2317 6.03345L11.3983 2.09752C10.5483 1.5243 9.45167 1.5243 8.6025 2.09752L2.76833 6.03345C2.07833 6.49836 1.66667 7.27322 1.66667 8.10556V17.5005C1.66667 17.9595 2.04 18.3336 2.5 18.3336C2.96 18.3336 3.33333 17.9595 3.33333 17.5005V10.8351C3.33333 9.45698 4.455 8.33552 5.83333 8.33552ZM9.16667 19.1668V17.5005C9.16667 17.0406 8.79417 16.6673 8.33333 16.6673H7.5C7.03917 16.6673 6.66667 17.0406 6.66667 17.5005V19.1668C6.66667 19.6267 7.03917 20 7.5 20H8.33333C8.79417 20 9.16667 19.6267 9.16667 19.1668ZM9.16667 14.1678V12.5014C9.16667 12.0415 8.79417 11.6682 8.33333 11.6682H7.5C7.03917 11.6682 6.66667 12.0415 6.66667 12.5014V14.1678C6.66667 14.6277 7.03917 15.0009 7.5 15.0009H8.33333C8.79417 15.0009 9.16667 14.6277 9.16667 14.1678ZM13.3333 19.1668V17.5005C13.3333 17.0406 12.9608 16.6673 12.5 16.6673H11.6667C11.2058 16.6673 10.8333 17.0406 10.8333 17.5005V19.1668C10.8333 19.6267 11.2058 20 11.6667 20H12.5C12.9608 20 13.3333 19.6267 13.3333 19.1668Z"
          fill="white"
        />
      </svg>
    ),
    show:userLoginType !== "Field" // If userLoginType "Field" then show is false
  },
  {
    path: "/inventory-counts",
    name: "Inventory Counts",

    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          d="M21 16V7.99999C20.9996 7.64927 20.9071 7.3048 20.7315 7.00116C20.556 6.69751 20.3037 6.44536 20 6.26999L13 2.26999C12.696 2.09446 12.3511 2.00204 12 2.00204C11.6489 2.00204 11.304 2.09446 11 2.26999L4 6.26999C3.69626 6.44536 3.44398 6.69751 3.26846 7.00116C3.09294 7.3048 3.00036 7.64927 3 7.99999V16C3.00036 16.3507 3.09294 16.6952 3.26846 16.9988C3.44398 17.3025 3.69626 17.5546 4 17.73L11 21.73C11.304 21.9055 11.6489 21.9979 12 21.9979C12.3511 21.9979 12.696 21.9055 13 21.73L20 17.73C20.3037 17.5546 20.556 17.3025 20.7315 16.9988C20.9071 16.6952 20.9996 16.3507 21 16Z"
          stroke="white"
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M3.27002 6.96001L12 12.01L20.73 6.96001" stroke="white" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12 22.08V12" stroke="white" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    ),
    show:userLoginType !== "Field" // If userLoginType "Field" then show is false

  },
  {
    path: "/employees-management",
    name: "Employees",

    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M12 12C12.9889 12 13.9556 11.7068 14.7778 11.1573C15.6001 10.6079 16.241 9.82705 16.6194 8.91342C16.9978 7.99979 17.0969 6.99445 16.9039 6.02455C16.711 5.05465 16.2348 4.16373 15.5355 3.46447C14.8363 2.76521 13.9454 2.289 12.9755 2.09608C12.0055 1.90315 11.0002 2.00217 10.0866 2.3806C9.17295 2.75904 8.39206 3.3999 7.84265 4.22215C7.29324 5.0444 7 6.01109 7 7C7.00132 8.32568 7.52853 9.59668 8.46593 10.5341C9.40332 11.4715 10.6743 11.9987 12 12ZM12 3.66667C12.6593 3.66667 13.3037 3.86217 13.8519 4.22844C14.4001 4.59471 14.8273 5.1153 15.0796 5.72439C15.3319 6.33348 15.3979 7.0037 15.2693 7.6503C15.1407 8.29691 14.8232 8.89085 14.357 9.35702C13.8908 9.8232 13.2969 10.1407 12.6503 10.2693C12.0037 10.3979 11.3335 10.3319 10.7244 10.0796C10.1153 9.82731 9.59471 9.40006 9.22843 8.8519C8.86216 8.30374 8.66667 7.65927 8.66667 7C8.66667 6.11595 9.01786 5.2681 9.64298 4.64298C10.2681 4.01786 11.1159 3.66667 12 3.66667Z" fill="white" />
        <path d="M12 14C10.1441 14.0021 8.36483 14.7614 7.05253 16.1112C5.74022 17.461 5.00206 19.2911 5 21.2C5 21.4122 5.08194 21.6157 5.22781 21.7657C5.37367 21.9157 5.5715 22 5.77778 22C5.98406 22 6.18189 21.9157 6.32775 21.7657C6.47361 21.6157 6.55556 21.4122 6.55556 21.2C6.55556 19.7148 7.12916 18.2904 8.1502 17.2402C9.17123 16.19 10.556 15.6 12 15.6C13.444 15.6 14.8288 16.19 15.8498 17.2402C16.8708 18.2904 17.4444 19.7148 17.4444 21.2C17.4444 21.4122 17.5264 21.6157 17.6722 21.7657C17.8181 21.9157 18.0159 22 18.2222 22C18.4285 22 18.6263 21.9157 18.7722 21.7657C18.9181 21.6157 19 21.4122 19 21.2C18.9979 19.2911 18.2598 17.461 16.9475 16.1112C15.6352 14.7614 13.8559 14.0021 12 14Z" fill="white" />
      </svg>
    ),
  },
  {
    path: "/support-issues",
    name: "Support Issues",

    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M6.16741 5.33333C6.16741 4.87333 6.54081 4.5 7.00089 4.5H8.66786V2.83333C8.66786 2.37333 9.04126 2 9.50134 2C9.96142 2 10.3348 2.37333 10.3348 2.83333V4.5H12.0018C12.4619 4.5 12.8353 4.87333 12.8353 5.33333C12.8353 5.79333 12.4619 6.16667 12.0018 6.16667H10.3348V7.83333C10.3348 8.29333 9.96142 8.66667 9.50134 8.66667C9.04126 8.66667 8.66786 8.29333 8.66786 7.83333V6.16667H7.00089C6.54081 6.16667 6.16741 5.79333 6.16741 5.33333ZM21.3668 12.8458L15.695 19.21C14.1147 20.9833 11.8468 22 9.47217 22H5.33393C3.49527 22 2 20.505 2 18.6667V14.5C2 12.6617 3.49527 11.1667 5.33393 11.1667H12.7169C13.6663 11.1667 14.4989 11.6742 14.9582 12.4317L17.6386 9.48667C18.0904 8.99083 18.7072 8.7 19.3773 8.66917C20.0466 8.63167 20.6883 8.86917 21.1843 9.32083C22.1961 10.2433 22.2778 11.8242 21.3668 12.845V12.8458ZM20.0616 10.5533C19.8957 10.4025 19.679 10.3308 19.4548 10.335C19.2297 10.3458 19.023 10.4425 18.8714 10.6092L15.1824 14.6625C14.8615 15.555 14.0663 16.2383 13.087 16.3783L8.78538 16.9925C8.33113 17.0583 7.90772 16.7417 7.84271 16.2858C7.7777 15.83 8.09442 15.4083 8.5495 15.3433L12.8511 14.7292C13.317 14.6625 13.6688 14.2575 13.6688 13.7867C13.6688 13.2617 13.242 12.835 12.7169 12.835H5.33393C4.4146 12.835 3.66696 13.5825 3.66696 14.5017V18.6683C3.66696 19.5875 4.4146 20.335 5.33393 20.335H9.47217C11.3717 20.335 13.187 19.5217 14.4506 18.1033L20.1224 11.7383C20.4283 11.395 20.4008 10.8642 20.0616 10.5542V10.5533Z" fill="white" />
      </svg>
    ),
  },
];
export default menuItem;

import React, { useEffect, useState, useContext } from "react";
import { Modal } from "react-bootstrap";
import { useSnackbar } from "notistack";

import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BackArrow from "../../../assets/images/back-arrow.svg";
import Constant from "../../../shared/_helpers/constants";
import UserContext from "../../../context/userContext";

import "./EditEmployees.css";

const AddEmployees = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const account = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  const [userType, setUserType] = useState("");
  const [adminAccess, setAdminAccess] = useState("N");
  const [barcodeAccess, setBarcodeAccess] = useState("N");
  const [employeeData, setEmployeData] = useState({});
  const [userWarehouse, setUserWarehouse] = useState("");
  const [confirmModal, setShowConfirmModal] = useState(false);
  const [warehouseList, setWarehouseList] = useState([]);
  const [isWarehouseMessage, setIsWarehouseMessage] = useState("Loading...");

  const CanoID = account.CanoID;

  useEffect(() => {
    const getUsers = async () => {
      try {
        const data = location.state?.data || [];
        console.log(data);
        const [userList, UsersCompany] = await Promise.all([
          axios.get(`${Constant.BASE_URL}/api/APIv1GetUser?CanoID=${CanoID}&Email=${data["email"]}`),
          axios.get(`${Constant.BASE_URL}/api/APIv1GetCompaniesForEmail?CanoID=${CanoID}&Email=${data["email"]}`),
        ]);

        setEmployeData(userList.data);
        setUserWarehouse(userList.data.DefaultCompany + "-.-" + userList.data.DefaultWarehouse);
        console.log("userList.data.UserType",userList.data.UserType)
        setUserType(userList.data.UserType);
        setAdminAccess(userList.data.AdminPanel);
        setBarcodeAccess(userList.data.BarcodePanel);

        if (UsersCompany?.data?.data.length === undefined) {
          setIsWarehouseMessage("No data available");
          return enqueueSnackbar(`No data found assign to this user`, { variant: "error" });
        }

        const UserCompArry = UsersCompany?.data?.data?.map((user) => user.Company_Code);
        let AllWarehousesData = [];
        const fetchPromises = UserCompArry.map((cc) => {
          return axios.get(`${Constant.BASE_URL}/api/APIv1GetWarehouseListByCompany?CanoID=${CanoID}&CompanyCode=${cc}`);
        });

        const responses = await Promise.all(fetchPromises);
        for (let i = 0; i < UserCompArry.length; i++) {
          const data = responses[i].data;
          if (data.status) {
            AllWarehousesData.push(...data.data);
          }
        }
        setWarehouseList(AllWarehousesData);
      } catch (err) {
        console.error(err);
        enqueueSnackbar(`${err.response.data.message}`, { variant: "error" });
      }
    };

    getUsers();
  }, [CanoID, location.state?.data, enqueueSnackbar]);

  const handleConfirmSave = () => {
    setShowConfirmModal(false);
    axios
      .post(`${Constant.BASE_URL}/api/APIv1SaveUser`, {
        ...employeeData,
        AdminPanel: adminAccess,
        UserType: userType,
        DefaultWarehouse: userWarehouse.split("-.-")[1].trim(),
        DefaultCompany: userWarehouse.split("-.-")[0].trim(),
        CompanyCode: [userWarehouse.split("-.-")[0].trim()],
        BarcodePanel: barcodeAccess,
      })
      .then((response) => {
        enqueueSnackbar(`User saved successfully`, { variant: "success" });
        navigate("/employees-management");
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(`${err.response.data.message}`, { variant: "error" });
      });
  };

  return (
    <div className="home_content inner-home-content itemDetails">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="page-heading d-flex">
              <Link to="/employees-management">
                <img src={BackArrow} alt="arrow" />
              </Link>
              <h4>Employee Management</h4>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-lg-12">
            <div className="custom-table py-2 px-3" style={{ borderRadius: "5px", background: "#FFFFFF" }}>
              <div className="row mx-1" style={{ overflowX: "auto" }}>
                <div className="col-lg-3 col-12 my-2">
                  <div className="table-inner-content detail">
                    <label>User Type</label>
                    <select
                      className="form-control"
                      onChange={(e) => {
                        setUserType(e.target.value);
                      }}
                      value={userType}
                    >
                      <option value="Inventory Staff">Inventory Staff</option>
                      <option value="Tech">Tech</option>
                      <option value="Hybrid User">Hybrid User</option>
                      <option value="Field">Field</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-5 col-12 my-2">
                  <div className="table-inner-content detail">
                    <label>Employee</label>
                    <select className="form-control" disabled>
                      <option>{employeeData["FName"]}</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-4 col-12 my-2">
                  <div className="table-inner-content detail">
                    <label>Warehouse</label>
                    <select
                      className="form-control"
                      onChange={(e) => {
                        setUserWarehouse(e.target.value);
                      }}
                      value={userWarehouse}
                    >
                      {warehouseList.length === 0 ? ( // Check the loading state
                        <option value="" disabled>
                          {isWarehouseMessage}
                        </option>
                      ) : (
                        warehouseList?.map((warehouse, index) => (
                          <option
                            value={warehouse.Company_Code.trim() + "-.-" + warehouse.Warehouse_Code.trim()}
                            key={warehouse.Company_Code + warehouse.Warehouse_Code + index}
                          >
                            {warehouse.Company_Code.trim() + "-" + warehouse.Name.trim()}
                          </option>
                        ))
                      )}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row mx-1" style={{ overflowX: "auto" }}>
                <div className="col-lg-2 col-12 my-2">
                  <div className="table-inner-content detail">
                    <label>Admin Access</label>
                    <select
                      className="form-control"
                      onChange={(e) => {
                        setAdminAccess(e.target.value);
                      }}
                      value={adminAccess}

                    >
                      <option value="N">No</option>
                      <option value="Y">Yes</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-2 col-12 my-2">
                  <div className="table-inner-content detail">
                    <label>Barcode Access</label>
                    <select
                      className="form-control"
                      onChange={(e) => {
                        setBarcodeAccess(e.target.value);
                      }}
                      value={barcodeAccess}
                    >
                      <option value="N">No</option>
                      <option value="Y">Yes</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-lg-12">
            <div className="custom-table py-2 px-3" style={{ borderRadius: "5px", background: "#FFFFFF" }}>
              <div className="row px-2 d-flex justify-content-between">
                <div className="col-lg-auto col-md-6 col-12 my-2">
                  <div className="table-inner-content detail">
                    <h5>Employee Name</h5>
                    <p className="m-0">{employeeData?.FName + employeeData?.LName || "-"}</p>
                  </div>
                </div>
                <div className="col-lg-auto col-md-6 col-12 my-2">
                  <div className="table-inner-content detail">
                    <h5>Emp.ID</h5>
                    <p className="m-0">{employeeData?.EmployeeCode || "-"}</p>
                  </div>
                </div>
                <div className="col-lg-auto col-md-6 col-12 my-2">
                  <div className="table-inner-content detail">
                    <h5>Email ID</h5>
                    <p className="m-0">{employeeData?.email || "-"}</p>
                  </div>
                </div>
                <div className="col-lg-auto col-md-6 col-12 my-2">
                  <div className="table-inner-content detail">
                    <h5>Contact No.</h5>
                    <p className="m-0">{employeeData?.Phone || "-"}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-3">
          <div className="row">
            <div className="col-lg-6 col-6">
              <div className="cancel-btn">
                <button onClick={() => navigate("/employees-management")} style={{ opacity: userType !== "" ? "1" : "0.5" }}>
                  Cancel
                </button>
              </div>
            </div>
            <div className="col-lg-6 col-6">
              <div className="submit-btn">
                <button
                  className="btn btn-primary"
                  onClick={(e) => {
                    setShowConfirmModal(true);
                  }}
                  style={{ opacity: userType !== "" ? "1" : "0.5" }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>

          <Modal
            show={confirmModal}
            onHide={(e) => {
              setShowConfirmModal(false);
            }}
            centered
          >
            <Modal.Header>
              <h6 style={{ fontWeight: "600", marginBottom: "0!important" }}>Are you Sure?</h6>
            </Modal.Header>
            <Modal.Body>
              <span style={{ fontSize: "14px" }}>Are you sure you want to edit the employee details?</span>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="cancel_btn"
                onClick={(e) => {
                  setShowConfirmModal(false);
                }}
              >
                Cancel
              </button>
              <button className="conform_btn" onClick={handleConfirmSave}>
                Confirm
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default AddEmployees;

import React from "react";

import menuItem from "./menuItem";
import { NavLink } from "react-router-dom";

import "./sidebar.css";

const Sidebar = ({ isOpen }) => {
  return (
    <div className="sidebarandnavbar">
      <div className="main-content">
        {/* <div className="sidebar" style={{ width: isOpen ? "220px" : "70px" }}> */}
        <div className={`sidebar ${isOpen ? "sidebaropen" : ""}`}>
          {menuItem
          .filter(item => item.show !== false)
           .map((item, index) => {
            return (
              <ul className="nav_list" key={index}>
                <li>
                  <NavLink
                    to={item.path}
                    as="Link"
                    key={index}
                    style={{
                      width: isOpen ? "100%" : "70px",
                      justifyContent: isOpen ? "initial" : "center",
                      height: "60px",
                    }}
                    className="link"
                  >
                    {item.icon}

                    <div
                      className="link_text"
                      style={{ display: isOpen ? "block" : "none" }}
                    >
                      {item.name}
                    </div>
                  </NavLink>
                </li>
              </ul>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

import React from "react";
import { GoogleLogin, googleLogout } from "@react-oauth/google";
import { Container, Row, Col } from "react-bootstrap";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import axios from "axios";
import Login_bg from "../../assets/images/login-bg.jpg";
import scan_vec from "../../assets/images/scan_vec.png";
import Logo from "../../assets/images/logo.png";
import "./Login.css";

const Login = () => {
  const { instance } = useMsal();

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split("-").map(Number);
    return new Date(year, month - 1, day);
  };

  // const checkCompanyPaymentStatus = (c_payment) => {
  //   console.log("c_payment",c_payment)
  //   const currentDate = new Date();
  //   const paymentDate = new Date(
  //     c_payment.data?.StripePaymentStatus?.split(" - ")[1]
  //   );
  //   if (
  //     moment(c_payment.data["JoiningDate"])
  //       .add(c_payment.data["TrialDays"], "days")
  //       .format("YYYY-MM-DD") >= moment().format("YYYY-MM-DD")
  //   ) {
  //     return true;
  //   } else if (
  //     paymentDate.getMonth() === currentDate.getMonth() &&
  //     paymentDate.getFullYear() === currentDate.getFullYear()
  //   ) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  const checkCompanyPaymentStatus = (c_payment) => {
    const { JoiningDate, TrialDays, StripePaymentCharged } = c_payment?.data; 
    const currentDate = new Date();
  
    if (JoiningDate && TrialDays) {
      const joiningDate = new Date(JoiningDate);
      const trialEndDate = new Date(joiningDate);
      trialEndDate.setDate(trialEndDate.getDate() + TrialDays);
  
      if (currentDate <= trialEndDate) {
        return true; 
      }
    }
  
    if (StripePaymentCharged === true) {
      return true; 
    }
  
    return false;
  };
  

  const checkEmployeePaymentStatus = (e_payment) => {
    if (parseDate(e_payment.StripeSubscriptionID) >= new Date()) {
      return true;
    } else {
      return false;
    }
  };
  
  const handleLogin = () => {
    instance
      .loginPopup(loginRequest)
      .then((res) => {
        const Email = res.account.username.toLowerCase();
        // const Email = "scano@canoconsultinginc.com"; //static email
        axios
          .get(
            `https://celtic.azurewebsites.net/api/APIv1GetUserFromEmail?Email=${Email.replaceAll(
              "+",
              "%2B"
            )}`
          )
          .then(({ data }) => {

            if (data?.AdminPanel === "Y" && data?.Disabled === "N") {
              axios
                .get(
                  `https://celtic.azurewebsites.net/api/APIv1GetCanoAccount?CanoID=${data.CanoID}`
                )
                .then((companyData) => {
                  if (companyData.data.Disabled === false) {
                    const companyPaymentStatus =
                      checkCompanyPaymentStatus(companyData);
                    const employeePamentStatus =
                      checkEmployeePaymentStatus(data);
                    console.log({ companyPaymentStatus, employeePamentStatus });
                    if (companyPaymentStatus || employeePamentStatus) {
                      data["Payment"] = true;
                    } else {
                      data["Payment"] = false;
                    }

                    data["auth"] = "msoft";
                    localStorage.setItem(
                      "CanoUserDetails",
                      JSON.stringify(data)
                    );
                    window.location.href = "/";
                  } else {
                    alert("Your company is not authorized to access this");
                    return instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                  }
                })
                .catch((error) => {
                  console.log(error);
                  alert("You are not authorized to access this");
                  return instance.logoutRedirect({
                    postLogoutRedirectUri: "/",
                  });
                });
            } else {
              alert("You are not authorized to access this");
              return instance.logoutRedirect({ postLogoutRedirectUri: "/" });
            }
          })
          .catch((err) => {
            console.log(err);
            return instance.logoutRedirect({ postLogoutRedirectUri: "/" });
          });
      })
      .catch((e) => {
        console.log(e);
        return instance.logoutRedirect({ postLogoutRedirectUri: "/" });
      });
  };

  const extractEmailFromGoogleResponse = (response) => {
    // Decode the JWT token to get user information
    const tokenParts = response.credential.split(".");
    const payload = JSON.parse(atob(tokenParts[1]));
    const email = payload.email;

    return email;
  };

  const handleGoogleLogin = (response) => {
    const Email = extractEmailFromGoogleResponse(response);
    // const Email = "canoconsulting@homelectrical.com";
    axios
      .get(
        `https://celtic.azurewebsites.net/api/APIv1GetUserFromEmail?Email=${Email.replaceAll(
          "+",
          "%2B"
        )}`
      )
      .then(({ data }) => {
        if (data?.AdminPanel === "Y" && data?.Disabled === "N") {
          axios
            .get(
              `https://celtic.azurewebsites.net/api/APIv1GetCanoAccount?CanoID=${data.CanoID}`
            )
            .then((companyData) => {
              if (companyData.data.Disabled === false) {
                const companyPaymentStatus =
                  checkCompanyPaymentStatus(companyData);
                const employeePamentStatus = checkEmployeePaymentStatus(data);
                console.log({ companyPaymentStatus, employeePamentStatus });
                if (companyPaymentStatus || employeePamentStatus) {
                  data["Payment"] = true;
                } else {
                  data["Payment"] = false;
                }

                data["auth"] = "google";

                localStorage.setItem("CanoUserDetails", JSON.stringify(data));
                window.location.href = "/";
              } else {
                alert("Your company is not authorized to access this");
                googleLogout();
              }
            })
            .catch((error) => {
              console.log(error);
              alert("You are not authorized to access this");
              googleLogout();
            });
        } else {
          alert("You are not authorized to access this");
          googleLogout();
        }
      })
      .catch((err) => {
        alert("You are not authorized to access this");
        console.log(err);
        googleLogout();
      });
  };

  return (
    <div
      className="login"
      id="login"
      style={{ backgroundImage: `url(${Login_bg})` }}
    >
      <Container>
        <Row>
          <Col lg={5}></Col>
          <Col lg={7}>
            <div className="content-mid">
              <div className="mng-login-des">
                <div className="login-bg">
                  <div className="login-logo text-center">
                    <img
                      src={Logo}
                      className="img-fluid"
                      alt="CANO LOGO"
                      width="200px"
                    />
                  </div>
                  <div className="form-style">
                    <div className="text-center py-4">
                      <img
                        className="align-center "
                        src={scan_vec}
                        alt="scanner"
                        width="120px"
                      />
                    </div>
                    <h2 className="heading text-center">
                      Welcome to Cano Consulting
                    </h2>
                    <p className="text-center">
                      Sign in now and dive into the world of seamless scanning.
                    </p>
                    <div className="text-center pt-2">
                      <button
                        onClick={() => handleLogin("microsoft")}
                        type="button"
                        className="primary-btn"
                      >
                        Sign in with Microsoft
                      </button>
                      <div className="google-login-container">
                        <GoogleLogin
                          clientId="43282715203-n0ic33sde051nc28as9mr19c1h7jg999.apps.googleusercontent.com"
                          onSuccess={(response) => handleGoogleLogin(response)}
                          onFailure={() => console.log("Google login failed")}
                          theme="filled_blue"
                          shape="circle"
                          size="large"
                          width="255px"
                          render={(renderProps) => (
                            <button
                              onClick={renderProps.onClick}
                              disabled={renderProps.disabled}
                              type="button"
                              className="primary-btn"
                            >
                              Sign in with Google
                            </button>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
